.top1 {
  max-width: 1280px;
  margin: 0 auto;
  background: url("../../public/assets/side.png");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: right 30% bottom 25%;
}


.top1_contents {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  width: 250px;
  margin-top: 9rem;
}

.mid_section {
  margin: 4rem;
}

.mid_section>h2 {
  font-size: 1.5rem;
}

.mid_section h1 {
  font-weight: 600;
  font-family: "Poppins";
}

.a_tag {
  font-size: 1.3rem;

  text-decoration: none;
}


.contact_us {
  max-width: 1280px;
  justify-content: space-between;
  margin: 4rem;
  display: flex;
}

.Left_cont {
  display: flex;
  flex-direction: column;

}

.Left_cont>h3 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
  color: #fff;
}

.Right_cont {
  margin-right: 6rem;
}

.side {
  width: 400px;
  height: 400px;
  margin-top: -40px;

}

.icons {
  margin-top: 5rem;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-self: flex-end;
}

.icons img {
  width: 25px;
  height: 25px;
}

.btn_group {
  display: flex;
  gap: 1rem;
  margin: 4rem;
  align-items: start;
  justify-content: start;
  position: relative;
  top: -.5rem;
}

.PROOF,
.NFT {
  border: none;
  border-radius: 5rem;
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 800;
  width: fit-content;
  padding: .4rem 30px;
  margin-top: -20px;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .header_content h1 {
    font-size: 24px;
  }

  .content_left h1 {
    font-size: 20px;
  }

  .logo {
    margin-top: 6rem;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .mid_section {
    margin: 3rem 1rem;
  }

  .contact_us {
    margin: 3rem 1rem;
  }

  .btn_group {
    margin: 3rem 1rem;
  }
}

@media (max-width: 767px) {

  .PROOF,
  .NFT {
    border-radius: 1.5rem;
    font-size: 12px;
    font-weight: 600;
    padding: .2rem 20px;
  }
}

@media (max-width: 575px) {

}