a,
span {
  color: rgb(249 195 89);
  font-family: 'Poppins';
  font-weight: 500;
  font-weight: bold;
}

h2 {
  font-family: sans-serif;
  font-weight: 200;
  color: whitesmoke;

}

.top {
  margin-top: 6rem;
}

.logo {

  position: relative;
  top: -20px;
  width: 250px;

}

.top_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 3rem 0;
}

.line {
  border: 3px solid rgb(155 31 83);
  border-radius: 4px;
  margin-top: -29px;
}

.top_section {
  padding: 2rem 0;
  text-align: start;
  margin: 0 auto;

}

.top_section h3 {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 30px;
  font-weight: normal;
}

.btn_group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: start;
  justify-content: start;
}

.PROOF,
.NFT {
  border: none;
  border-radius: 5rem;
  background-color: #fff;
  color: black;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  /* height: 2rem; */
  padding: .4rem 30px;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
}


@media (max-width: 1199px) {
  .content_left h1 {
    font-size: 20px;
  }

  .top {
    margin-top: 3rem;
  }
}

@media (max-width: 1060px) {}

@media (max-width: 991px) {
  .top_content {
    flex-direction: column-reverse;
  }

  .top {
    margin-top: 1rem;
  }

  .logo {
    top: 0;
    align-self: flex-end;
  }

  .btn_group {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .header_content h1 {
    font-size: 21px;
  }

  .image_container {
    width: 70%;
  }

  .top_section h3 {
    font-size: 17px;
  }

  .PROOF,
  .NFT {
    border-radius: 1.5rem;
    font-size: 12px;
    font-weight: 600;
    padding: .2rem 20px;
  }
}

@media (max-width: 575px) {

  .image_container {
    width: 75%;
  }
}